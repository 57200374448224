.bg-s{
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.900);
    
}
.b1{
    margin-top: 3vh!important;
}
.b2{
    margin-top: 40vh!important;
}