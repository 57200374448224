

.back-main{
    background-color: rgb(0, 0, 0);
    position: relative;
    height: 200vh;
    margin-top: 1px;
}
.flex {
    display: flex;
}
.colour-txt{
    position:relative;
    position: sticky;
    top: 0;
    /* z-index: 100; */
}

.content{
    /* background-color:rgb(88, 5, 67)!important ; */
    /* background-color:rgb(255, 0, 221)!important ; */

    background-color: rgb(247, 73, 42)!important;
    border-color: brown!important;
    height:50vh;
    margin-top: 50px;
    border-bottom-left-radius: 80%!important;
    margin: 20px;
    opacity: 90%;
    padding: 10px;
}

.img{
    /* background-image: url("https://www.iotconnect.io/images/energy_diagram.png"); */
    height: 100px;
    width: 40px;
    margin: 20px;
    color: white;

}
.list{
    position: absolute;
    left: 400px!important;
    font-size: 30px;
    color: white;
}


@keyframes scaleAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(255, 165, 0, 0.7);
    }
    50% {
        transform: scale(1.0255);
        box-shadow: 0 0 10px rgba(255, 165, 0, 0.7);
    }
   
}

.img-a{
    animation: scaleAnimation 6s infinite; /* Adjust the duration as needed */
}
.back-i{
    background-image: url("https://www.iotconnect.io/images/energy_diagram.png")!important;
    height: 250px;
    margin-left: 62vw;
    position: absolute;
    top: 130px;
}

.b2{
    background-image: url("https://www.pngmart.com/files/12/Orange-Wave-PNG-Transparent-Picture.png");
    height: 70px;
    position: absolute;
}